<template>
    <div>
        <el-pagination
     
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :pageSize="pageSize"
        layout="total, prev, pager, next"
        :pagerCount="pagerCount"
        :total="total">
        </el-pagination>
    </div>
</template>
<script>
export default {
    props:{
        total:Number,
        pageSize:Number
    },
    mounted(){
        console.log(`33`,this.total)
        console.log(`44`,this.pageSize)
    },

    data(){
        return{
            
            // leftpagetotal:10,
            currentPage1:1,
            pagerCount:5
        }
    },
   
    methods:{
       
        handleCurrentChange(val){
             console.log(`2`,val)
            this.$emit("handleSizeChange",val)
        }
    }
}
</script>