<template>
    <div class="headertoab">
        <div class="dei_rowp">
            <el-row :gutter="1">
                <el-col :span="5">
                    <el-button type="primary" size="small" class="but" @click="handRegister">
                        <i class="el-icon-check"></i>
                        {{titlename.name}}
                    </el-button>
                </el-col>
                <el-col :span="6" >
                    <el-button type="primary" size="small" class="but" @click="handRegistertwo">
                        <i class="el-icon-check"></i>
                        {{titlename.titles}}
                    </el-button>
                </el-col>
                <el-col :span="6" v-if="titlename.tlnames">
                    <el-button type="primary" size="small" class="but" @click="handRegistertb">
                        <i class="el-icon-check"></i>
                        {{titlename.tlnames}}
                    </el-button>
                </el-col>
                <el-col :span="7">
                    <div @click="handFromshow"><el-input
                    class="dev_dowm"
                    :placeholder="$t('devtable.qdjcx')"
                    suffix-icon="el-icon-arrow-down"
                    :readonly="true"
                    size="small"
                    >
                    </el-input></div>
                </el-col>
            </el-row>
        </div>
        <!-- <div class="dei_rowp">
            <el-row>
                <el-col :span="4">
                    <el-button type="primary" size="small" class="but" @click="handRegister">
                        <i class="el-icon-check"></i>
                        {{titlename.tlnamest}}
                    </el-button>
                </el-col>
            </el-row>
        </div> -->
    </div>
</template>
<script>
export default {
    props:{
        titlename:Object,
        flags:Boolean
    },
    data(){
        return{
           

            }
        },
    methods:{
        handRegister(){
            this.$emit('handRegister')
        },
        handFromshow(){
            this.$emit('handFromshow')
        },
        Excelexport(){
            this.$emit('Excelexport')
        },
        handRegistertwo(){
            this.$emit('handRegistertwo')
        },
        handRegistertb(){
            this.$emit('handRegistertb')
        }
    }
}
</script>
<style lang="less" scoped>
.headertoab{
    display: flex;
}
.dei_rowp{
    padding-left: 20px;
}
</style>